<template>
  <v-sheet style="background-color: transparent" >
    <!--  Main webinar window -->
    <ws-dialog
      v-if="displayDialog"
      v-model="displayDialog"
      fullscreen
      not-centered
      no-padding
      hide-buttons
      no-navigation
      class="d-flex flex-md-column"
    >
      <template #header>
        <!--   Webinar room header   -->
        <ws-webinar-room-header
          @set-display="$emit('set-display', false)"
          :loading="loading"
          :name="webinar.title"
        />
      </template>

      <template #default >

        <v-sheet
          class="d-flex align-center justify-space-between pt-7 pb-6 px-6"
          :style="`height: 100%; background-color: ${wsDARKER}; column-gap: 16px`"
        >

          <v-sheet
            :style="`width: 100%; height: 100%; background-color: #31485A; border-radius: 10px;
              padding: 12px 12px 12px; position: relative; `"
          >
            <!--     Block with video (main)    -->
            <v-sheet
              :style="`width: ${ videoOn && !displayPreviewDialog ? 'fit-content' : '100%' };
              height: 100%; background-color: #3B5365; border-radius: 10px; position: relative; object-fit: contain; margin: auto`"
              class="d-flex align-center justify-center mb-4"
            >


              <div >
                <div class="d-flex">
                  <ws-button label="Connect" @click="connectToStream"></ws-button>
                </div>

                <ws-text-field
                    placeholder="webinarUuid"
                    v-model="webinarUuid"
                />
                <video
                    ref="video"
                    autoplay/>

                <h1>Video will be here</h1>

              </div>



              <v-icon v-if="stream" style="position: absolute; bottom: 16px; right: 16px; cursor: pointer" :color="wsATTENTION">
                mdi-hand-back-right
              </v-icon>

              <ws-prompt-dialog
                v-if="stream"
                :backgroundColor="wsWHITE"
                :description="$t('webinar.student.raise_hand_text')"
                bottom
                x="72%"
                y="6.5%"
                :text-color="wsACCENT"
                :value="stream"
                line-position="16px"
                width="310px"
              >
              </ws-prompt-dialog>



            </v-sheet>
          </v-sheet>

            <!--   Right Sidebar (Chat content)  -->
            <div v-if="checkedValue === 'chat'" style="min-width: 305px; height: 100%; background-color: #31485A; color: #fff" class="rounded ">
              <!--   Chat block -->
              <ws-sidebar-chat
                user
                checked-value="chat"
                @close="closeChat"
              />
            </div>

            <!--  Sidebar Menu Items -->
            <ws-right-sidebar-menu
              user
              v-if="!isActiveSidebarWindow"
              :loading="loading"
              @checked-value="checkedValue = $event"
              @active-sidebar-dialog="isActiveSidebarWindow = $event"
              :sidebar-window="isActiveSidebarWindow"
            />
        </v-sheet>
      </template>

    </ws-dialog>
  </v-sheet>
</template>

<script>

import WsWebinarRoomHeader from "@modules/webinars/components/student/webinar-room/UI/wsWebinarRoomHeader.vue";
import WsSidebarChat from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarChat.vue";
import WsPromptDialog from "@modules/webinars/components/UI/wsPromptDialog.vue"

import WsJanusClient from "@/modules/webinars/plugins/wsJanusClient";

export default {
  name: "StudentWebinarRoom.vue",
  components: {WsPromptDialog, WsSidebarChat,WsWebinarRoomHeader},
  props: {
    displayDialog: {
      type: Boolean,
      default: false
    },
    webinar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      stream: false, // active stream
      isActiveSidebarWindow: false,
      checkedValue: '',
      loading: false,
      webinarUuid : null,
    }
  },
  methods: {
    async connectToStream() {

      let result = await this.$store.dispatch('stream/GET_JANUS_START_TOKEN' , this.webinarUuid)

      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
      }

      const videoDom = this.$refs.video

      let janus = new WsJanusClient()
      this.janus = janus

      janus.startStream({
        token : result.token,
        roomId : this.webinarUuid,
        userId : this.$store.state.auth.userID,
        videoDom : videoDom,

        videoCallback : () => {
          this.notify('I AM VIDEO CALLBACK')
        }

      })

      this.notify(`I will connect to room: ${this.webinarUuid} ` , 'dark')
    },
    closeChat() {
      this.checkedValue = '';
      this.isActiveSidebarWindow = false
    }
  },
  mounted() {
    // example
    setTimeout(() => {
      this.stream = true
    }, 2000)
  }
}
</script>

<style scoped>

</style>
